@import "src/shared/assets/styles";

.button {
  backface-visibility: hidden;
  border-radius: $input-border-radius;
  border-width: 0;
  box-sizing: border-box;
  background-color: $main-light;
  cursor: pointer;
  height: 38px;
  font: $p-medium;
  outline: none;
  overflow: hidden;
  padding: 0 25px;
  position: relative;
  text-align: center;
  text-transform: none;
  transform: translateZ(0);
  transition: all .2s, box-shadow .08s ease-in;
  user-select: none;
  touch-action: manipulation;
  width: 100%;

  &:hover {
    background-color: darken($main-light, 5%);
  }

  &:active {
    background-color: darken($main-light, 10%);
  }

  &_primary {
    background-color: $main-blue;
    color: #fff;

    &:hover {
      background-color: darken($main-blue, 5%);
    }

    &:active {
      background-color: darken($main-blue, 10%);
    }
  }

  &_danger {
    background-color: $main-red;
    color: #fff;

    &:hover {
      background-color: darken($main-red, 5%);
    }

    &:active {
      background-color: darken($main-red, 10%);
    }
  }
}

:global([data-active="true"]){
  background-color: $main-light-blue;
  color: $main-red
}
