// colors
$main-dark-blue: #1d3557;
$main-blue: #457b9d;
$main-light-blue: #a8dadc;
$main-light: #f1faee;
$main-red: #e63946;

//fonts
$h1: 700 32px/40px Inter, sans-serif;
$h2: 500 24px/32px Inter, sans-serif;
$p-regular: 400 14px/20px Inter, sans-serif;
$p-medium: 600 14px/24px Inter, sans-serif;
$p-light: 300 14px/24px Inter, sans-serif;

//inputs

$input-border-radius: 6px;