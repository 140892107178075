@import "src/shared/assets/styles";

.backdrop{
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 500;
  background-color: transparentize($main-dark-blue, 0.2);
}