@import "src/shared/assets/styles";

.wrap {
  display: flex;
  flex-direction: column-reverse;
  font: $p-regular;
  margin-top: .5rem;
  margin-bottom: .5rem;
  position: relative;

  .textarea {
    padding: 10px;
    border-radius: $input-border-radius;
    outline: none;
    border: none;
    z-index: 1;
    position: relative;
    resize: none;

    &_error {
      border: 2px solid $main-red;
      box-shadow: 0 0 10px $main-red;
      border-radius: $input-border-radius;
    }

    &::placeholder {
      opacity: 0;
    }

    &:focus + .label, &:not(:placeholder-shown) + .label {
      position: relative;
      top: 0;
      font-size: .9em;
      left: 5px;
      color: $main-light;
    }
  }

  .label {
    color: $main-dark-blue;
    position: relative;
    top: 28px;
    left: 10px;
    z-index: 2;
    transition: .5s ease;
  }

  .error {
    font: $p-medium;
    color: $main-red;
    padding: 5px 10px 0;
  }
}
