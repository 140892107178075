@import 'src/shared/assets/styles';

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  background-color: $main-dark-blue;
  color: $main-light;
}

#root {
  padding-top: 2rem;
}

.container {
  width: 1200px;
  margin: 0 auto;
}
