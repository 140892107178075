@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Regular.eot');
  src: url('../fonts/Inter-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Inter-Regular.woff2') format('woff2'),
  url('../fonts/Inter-Regular.woff') format('woff'),
  url('../fonts/Inter-Regular.ttf') format('truetype'),
  url('../fonts/Inter-Regular.svg#Inter-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Medium.woff2') format('woff2'),
  url('../fonts/Inter-Medium.woff') format('woff'),
  url('../fonts/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-SemiBold.woff2') format('woff2'),
  url('../fonts/Inter-SemiBold.woff') format('woff'),
  url('../fonts/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Bold.eot');
  src: url('../fonts/Inter-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Inter-Bold.woff2') format('woff2'), url('../fonts/Inter-Bold.woff') format('woff'),
  url('../fonts/Inter-Bold.ttf') format('truetype'),
  url('../fonts/Inter-Bold.svg#Inter-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Regular.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
