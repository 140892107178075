@import "src/shared/assets/styles";

.wrap {
  display: flex;
  flex-direction: column-reverse;
  font: $p-regular;
  position: relative;
  width: 100%;
  .select {
    padding: 10px;
    border-radius: $input-border-radius;
    outline: none;
    border: none;
    z-index: 1;
    position: relative;

    &_error {
      border: 2px solid $main-red;
      box-shadow: 0 0 10px $main-red;
      border-radius: $input-border-radius;
    }

    &::placeholder {
      opacity: 0;
    }

    &:focus + .label, &:not(:placeholder-shown) + .label {
      position: relative;
      top: 0;
      font-size: .9em;
      left: 5px;
      color: $main-light;
    }
  }

  .error {
    font: $p-medium;
    color: $main-red;
    padding: 5px 10px 0;
  }
}
