@import "src/shared/assets/styles";

.table__row {
  td {
    padding: 10px;
    border: 1px solid $main-light;
    vertical-align: middle;
  }

  .btn_group {
    display: flex;
    align-items: center;

    button {
      margin-right: .5rem;
      margin-left: .5rem;
    }
  }
}
